<template>
    <!-- Start About -->
	<div class="about-section-box tw-pt-16">
		<div class="container">
			<div class="row tw-flex tw-flex-row lg:tw-flex-col-reverse">
				<div class="col-lg-6 lg:tw-mt-5">
					<img src="/storage/images/coco/about.jpg" alt="" class="img-fluid">
					 <p class="p_two tw-text-gray-50 tw-absolute tw-cursor-default">Cocco n Curry is one of the best South Indian restaurants in Gurgaon and one of the best family restaurants in Gurgaon and it is the place where you can have the best dosa in Gurgaon</p>
				</div>
				<div class="col-lg-6 text-start">
					<div class="inner-column tw-flex tw-flex-col">
						<h1 class="text-center">Our Story</h1>
						<hr class="tw-mb-10 md:tw-mb-5 tw-w-60 tw-mx-auto">
						<p class="p_one">Cocco n Curry is a building legacy and aims to bring the best of dining experience, ranging from authentic southern delicacies to aromatic northern flavours, including exotic Chinese and Italian cuisine catering to satisfy all your food cravings at one place. We present the amalgamation of global and traditional flavours with various fusion dishes to enrich your taste buds.</p>
						<br />
						<p class="p_one">With our beautiful ambience, Supreme standards of quality, service & hygiene and Our Warm and Empathetic Staff takes care and fulfils all your wishes in order to create a memorable and joyful experience. With our team having 25+ years of expertise, Devour the best of all cuisines.</p>
						<!-- <br /> -->
						<!-- <p class="p_two">Cocco n Curry is the place where you can find the best dosa in gurgaon</p> -->
						<router-link tag="a" class="tw-border-2 tw-border-maroon_new tw-block tw-w-32 tw-px-4 tw-py-2 tw-mx-auto tw-text-sm tw-rounded tw-no-underline hover:tw-no-underline hover:tw-text-white hover:tw-bg-maroon_new tw-font-semibold tw-text-maroon_new tw-uppercase tw-mt-5 tw-text-center" to="/about-us">Read More</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- End About -->
</template>

<script>
export default {
    name: "Welcome",
	data() {
		return {
			showLine: false,
		}
	},
}
</script>

<style scoped>

/*------------------------------------------------------------------
    About
-------------------------------------------------------------------*/

.about-section-box{
	padding-bottom: 50px;
}

.about-section-box .img-fluid{
	box-shadow: -10px -10px 0px #88381f;
}
.inner-column h1{
	font-family: 'Kaushan Script', cursive !important;
	font-size: 50px;
	font-weight: bold;
	color: #88381f;
	text-transform: capitalize ;
}
.inner-column .p_one{
	color: #686868;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    line-height: 2;
}

.p_two{
	font-size: 14px;
    font-family: 'Roboto', sans-serif;
    line-height: 2;
}

.a_one:hover {
	text-decoration: none;
}

.inner-column .btn-outline-new-white{
	color: #88381f;
}
.inner-column .btn-outline-new-white:hover{
	color: #ffffff;
}

@media (max-width: 767px) {
	.inner-column h1{
		font-size: 40px;
		color: #88381f;
		margin-top: 0px;
	}

	.about-section-box .img-fluid{
		margin-top: 20px;
	}

	.about-section-box{
		padding-top: 20px;
	}
}

</style>
