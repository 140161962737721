<template>
  <HeadPic :title="sectionTitle" :picture="sectionPicture" />

  <div id="about" class="about-main tw-pt-5 tw-pb-10">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="block-title">About Us</h2>
          <hr class="tw-mb-10 md:tw-mb-5 tw-w-52 tw-mx-auto" />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div class="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.1s">
            <p class="p_one">
              We at Cocco n Curry aspire to provide surpassing and amicable
              hospitality services to our guests by presenting the best quality
              of food and services with a great ambience.
            </p>
            <br />
            <p class="p_one">
              Our Food Menu has been Curated by our Best Industry Chefs to
              provide you with delectable mouth watering dishes of different
              regions from South India to North India and international cuisines
              like Chinese & Continental. The must try dishes at Cocco n Curry are
              crunchy cheesey idli, Spring Roll Dosa to Lahori aloo, Dal
              Makhni and pudina Paratha, End your meal with our special Phirni
              and aromatic Filter Coffee served in the traditional Steel tumbler
              and saucer.
            </p>
            <br />
            <p class="p_one">
              With our Team having 25+ years of expertise, Devour the best of
              all cuisines to rejuvenate your taste buds and memorable
              experience.
            </p>
          </div>
        </div>
        <!-- end col -->
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div class="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.1s">
            <div
              class="
                about-images"
            >
              <img
                class="about-main tw-shadow-md tw-transition-all tw-duration-500 tw-mx-auto tw-border-8 tw-border-gray-200 tw-rounded tw-w-full"
                src="/storage/images/coco/about_2.jpg"
                alt="About Main Image"
              />
              <p class="p_two tw-text-gray-50 tw-absolute tw-text-left tw-cursor-default">Cocco n Curry is the best South Indian restaurant in sapphire 90 mall and is the place where you can find the best dosa in Gurgaon</p>

              <!-- <img class="about-inset" src="images/about-inset.jpg" alt="About Inset Image"> -->
            </div>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </div>
  <Social />
</template>

<script>
import HeadPic from "../../components/Header/HeadPic";
import Social from '../../components/Social';
export default {
  name: "AboutUs",
  components: {
    HeadPic,
    Social,
  },
  data() {
    return {
      sectionTitle: "About",
      sectionPicture: "/storage/images/coco/bg_about.jpeg",
    };
  },
};
</script>

<style scoped>
/*------------------------------------------------------------------
    ABOUT AREA
-------------------------------------------------------------------*/

/* .about-main{
	background:#E6E6E6;
} */

.block-title {
  font-family: "Kaushan Script", cursive !important;
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  color: #88381f;
}

.about-images {
  text-align: center;
  position: relative;
  /* top: 50px; */
}

/* .about-main {
  padding: 20px 0px;
} */

.about-main h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 20px;
  padding: 40px 0px;
}

.about-main .p_one {
  color: #686868;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  line-height: 2;
}

.p_two{
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  line-height: 2;
}

.left-btn {
  padding-top: 10px;
}

/* .about-images img {
    border: 10px solid #f1f1f1;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
} */

.about-images img {
  /* border: 10px solid #a19f9f; */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 100%;
  max-height: 350px;
  background-position: center;
}

.about-inset {
  position: absolute;
  top: 0;
  right: 0;
}

@media (max-width: 767px) {
  .block-title {
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    color: #88381f;
  }

  .about-images {
    top: 20px;
  }
}
</style>
