<template>
  <HeadPic :title="sectionTitle" :picture="sectionPicture" />

  <!--================ Menu Area =================-->
  <section class="menu_area section_gap tw-pt-5 tw-pb-10">
    <div class="container">
      <div class="row menu_inner">
          <div class="col-12">
            <h2 class="block-title">Food Menu</h2>
            <hr class="tw-mb-10 md:tw-mb-5 tw-w-56 tw-mx-auto" />
        </div>
        <div class="col-lg-5">

          <div class="menu_list">
            <h1>Soups</h1>
            <ul class="list">
              <li v-for="soup in dishesSoups" :key="soup.data.dish_id">
                <h4>{{ soup.data.attributes.title }}</h4>
                <p>({{ soup.data.attributes.description }})</p>
              </li>
            </ul>
          </div>

          <div class="menu_list">
            <h1>Appetizers</h1>
            <ul class="list">
              <li v-for="appetizer in dishesAppetizers" :key="appetizer.data.dish_id">
                <h4>{{ appetizer.data.attributes.title }}</h4>
                <p>({{ appetizer.data.attributes.description }})</p>
              </li>
            </ul>
          </div>

           <div class="menu_list">
            <h1>Special Dosas</h1>
            <ul class="list">
              <li v-for="dosa_dish in dishesDosa" :key="dosa_dish.data.dish_id">
                <h4>{{ dosa_dish.data.attributes.title }}</h4>
                <p>({{ dosa_dish.data.attributes.description }})</p>
              </li>
            </ul>
          </div>

           <div class="menu_list">
            <h1>Uthappam</h1>
            <ul class="list">
              <li v-for="uthappam in dishesUthappams" :key="uthappam.data.dish_id">
                <h4>{{ uthappam.data.attributes.title }}</h4>
                <p>({{ uthappam.data.attributes.description }})</p>
              </li>
            </ul>
          </div>

          <div class="menu_list">
            <h1>South Specials</h1>
            <ul class="list">
              <li v-for="south_dosa_dish in dishesSouthDosa" :key="south_dosa_dish.data.dish_id">
                <h4>{{ south_dosa_dish.data.attributes.title }}</h4>
                <p>({{ south_dosa_dish.data.attributes.description }})</p>
              </li>
            </ul>
          </div>

          <div class="menu_list">
            <h1>Rice</h1>
            <ul class="list">
              <li v-for="rice in dishesRice" :key="rice.data.dish_id">
                <h4>{{ rice.data.attributes.title }}</h4>
                <p>({{ rice.data.attributes.description }})</p>
              </li>
            </ul>
          </div>

        </div>

        <div class="col-lg-5 tw-ml-auto lg:tw-ml-0 lg:tw-mt-16">

          <div class="menu_list">
            <h1>North Indian Curry's</h1>
            <ul class="list">
              <li v-for="nic_dish in dishesNorthIndianCurry" :key="nic_dish.data.dish_id">
                <h4>{{ nic_dish.data.attributes.title }}</h4>
                <p>({{ nic_dish.data.attributes.description }})</p>
              </li>
            </ul>
          </div>

          <div class="menu_list">
            <h1>Combo's</h1>
            <ul class="list">
              <li v-for="combo in dishesCombos" :key="combo.data.dish_id">
                <h4>{{ combo.data.attributes.title }}</h4>
                <p>({{ combo.data.attributes.description }})</p>
              </li>
            </ul>
          </div>

          <div class="menu_list">
            <h1>Salad's And Raita</h1>
            <ul class="list">
              <li v-for="sar in dishesSaladAndRaitas" :key="sar.data.dish_id">
                <h4>{{ sar.data.attributes.title }}</h4>
                <p>({{ sar.data.attributes.description }})</p>
              </li>
            </ul>
          </div>

          <div class="menu_list">
            <h1>Chinese</h1>
            <ul class="list">
              <li v-for="dish_chinese in dishesChinese" :key="dish_chinese.data.dish_id">
                <h4>{{ dish_chinese.data.attributes.title }}</h4>
                <p>({{ dish_chinese.data.attributes.description }})</p>
              </li>
            </ul>
          </div>

          <div class="menu_list">
            <h1>Breads</h1>
            <ul class="list">
              <li v-for="bread in dishesBread" :key="bread.data.dish_id" class="pb-2">
                <h4>{{ bread.data.attributes.title }}</h4>
                <p v-if="bread.data.attributes.description">({{ bread.data.attributes.description }})</p>
              </li>
            </ul>
          </div>

          <div class="menu_list">
            <h1>Desserts</h1>
            <ul class="list">
              <li v-for="dish_dessert in dishesDesserts" :key="dish_dessert.data.dish_id">
                <h4>{{ dish_dessert.data.attributes.title }}</h4>
                <p>({{ dish_dessert.data.attributes.description }})</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--================End Menu Area =================-->
  <Social />
</template>

<script>
import HeadPic from "../../components/Header/HeadPic";
import Social from '../../components/Social';
export default {
  name: "Menu",
  components: {
    HeadPic,
    Social,
  },
  data() {
    return {
      sectionTitle: "Menu",
      sectionPicture: "/storage/images/coco/bg_menu.jpeg",
      dishesNorthIndianCurry: [],
      dishesDosa: [],
      dishesSouthDosa: [],
      dishesAppetizers: [],
      dishesUthappams: [],
      dishesSaladAndRaitas: [],
      dishesCombos: [],
      dishesChinese: [],
      dishesRice: [],
      dishesBread: [],
      dishesDesserts: [],
      dishesSoups: [],
      isLoading: false,
    };
  },
  methods: {
    async fetchNorthIndianCurry() {
      try {
        const res = await fetch("/api/dishes/14");
        const result = await res.json();
        return result;
      } catch (err) {
        console.log(err);
      }
    },

    async fetchDosas() {
      try {
        const res = await fetch("/api/dishes/6");
        const result = await res.json();
        return result;
      } catch (err) {
        console.log(err);
      }
    },

    async fetchSouthDosas() {
      try {
        const res = await fetch("/api/dishes/7");
        const result = await res.json();
        return result;
      } catch (err) {
        console.log(err);
      }
    },

    async fetchAppetizers() {
      try {
        const res = await fetch("/api/dishes/5");
        const result = await res.json();
        return result;
      } catch (err) {
        console.log(err);
      }
    },

    async fetchSoups() {
      try {
        const res = await fetch("/api/dishes/8");
        const result = await res.json();
        return result;
      } catch (err) {
        console.log(err);
      }
    },

    async fetchUthappams() {
      try {
        const res = await fetch("/api/dishes/4");
        const result = await res.json();
        return result;
      } catch (err) {
        console.log(err);
      }
    },

    async fetchSaladAndRaitas() {
      try {
        const res = await fetch("/api/dishes/18");
        const result = await res.json();
        return result;
      } catch (err) {
        console.log(err);
      }
    },

    async fetchCombos() {
      try {
        const res = await fetch("/api/dishes/19");
        const result = await res.json();
        return result;
      } catch (err) {
        console.log(err);
      }
    },

    async fetchChinese() {
      try {
        const res = await fetch("/api/dishes/17");
        const result = await res.json();
        return result;
      } catch (err) {
        console.log(err);
      }
    },

    async fetchRice() {
      try {
        const res = await fetch("/api/dishes/21");
        const result = await res.json();
        return result;
      } catch (err) {
        console.log(err);
      }
    },

    async fetchBreads() {
      try {
        const res = await fetch("/api/dishes/22");
        const result = await res.json();
        return result;
      } catch (err) {
        console.log(err);
      }
    },

    async fetchDesserts() {
      try {
        const res = await fetch("/api/dishes/20");
        const result = await res.json();
        return result;
      } catch (err) {
        console.log(err);
      }
    },
  },
  async created() {
    this.dishesNorthIndianCurry = await this.fetchNorthIndianCurry();
    this.dishesDosa = await this.fetchDosas();
    this.dishesSouthDosa = await this.fetchSouthDosas();
    this.dishesAppetizers = await this.fetchAppetizers();
    this.dishesUthappams = await this.fetchUthappams();
    this.dishesSaladAndRaitas = await this.fetchSaladAndRaitas();
    this.dishesCombos = await this.fetchCombos();
    this.dishesChinese = await this.fetchChinese();
    this.dishesRice = await this.fetchRice();
    this.dishesBread = await this.fetchBreads();
    this.dishesDesserts = await this.fetchDesserts();
    this.dishesSoups = await this.fetchSoups();
  },
};
</script>

<style scoped>

.block-title {
  font-family: "Kaushan Script", cursive !important;
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  color: #88381f;
}

.menu_list h4 {
    font-family: 'Viga', sans-serif !important;
    color: #703b3b;
}

.menu_list p{
	font-size: 26px;
	font-weight: 200;
	margin: 0px;
	font-family: 'Roboto', sans-serif !important;
	color: #474545;
}

.menu_inner .col-lg-5 .menu_list:last-child {
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .menu_inner .col-lg-5 .menu_list:last-child {
    margin-bottom: 50;
  }
}

.menu_inner .menu_list {
  margin-bottom: 70px;
}

@media (max-width: 991px) {
  .menu_inner .menu_list.res-mr {
    margin-top: 50px;
  }
}

.menu_inner .menu_list h1 {
  font-family: 'Roboto', sans-serif;
  /* font-weight: 600; */
  font-size: 36px;
  margin-bottom: 50px;
  color: #433e3e;
  /* text-decoration: underline; */
}

.menu_inner .menu_list p {
  margin-bottom: 0px;
  font-size: 14px;
}

.menu_inner .menu_list .list li {
  padding-bottom: 30px;
}

.menu_inner .menu_list .list li:last-child {
  padding-bottom: 0;
}

.menu_inner .menu_list .list li h4 {
  font-size: 18px;
  margin-bottom: 12px;
  font-weight: 300;
}

@media (max-width: 420px) {
  .menu_inner .menu_list .list li h4 {
    font-size: 16px;
  }
}

.menu_inner .menu_list .list li h4:after {
  margin-right: 15px;
  content: ".......................";
  float: none;
}

@media (max-width: 1024px) {
  .menu_inner .menu_list .list li h4:after {
    content: "............";
  }
}

@media (max-width: 991px) {
  .menu_inner .menu_list .list li h4:after {
    content: "................................";
  }
}

.menu_inner .menu_list .list li h4 span {
  float: right;
  font-weight: 700;
}

@media (max-width: 420px) {
  .menu_inner .menu_list .list li p {
    font-size: 14px;
  }
}

</style>
