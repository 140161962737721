<template>
    <HeadPic :title="state.sectionTitle" :picture="state.sectionPicture" />

    <section id="mu-contact">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="mu-contact-area">
                        <div class="mu-title tw-text-center">
                            <h1 class="mu-subtitle">Get In Touch</h1>
                            <hr class="tw-mb-10 md:tw-mb-5 tw-w-60 tw-mx-auto" />
                            <p>Contact Us</p>

                            <div class="alert alert-success tw-text-center" v-if="state.authStatus === 'success'">
                                {{ state.authMessage }}
                            </div>

                            <!-- <div class="alert alert-danger tw-text-center" v-if="state.authStatus === 'error'">
                                {{ state.authMessage }}
                            </div> -->
                        </div>

                        <div class="mu-contact-content">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="mu-contact-left tw-relative">

                                         <div v-if="state.loading" class="tw-flex tw-justify-center tw-items-center tw-absolute tw-text-center tw-w-full" style="min-height:524px">
                                            <div class="spinner-grow inline-block tw-w-10 tw-h-10 bg-current rounded-full opacity-0 tw-text-maroon_new" role="status">
                                                <span class="visually-hidden"></span>
                                            </div>
                                        </div>

                                        <!-- Email message div -->
                                        <div id="form-messages"></div>
                                        <!-- Start contact form -->
                                        <form id="ajax-contact" method="post" class="mu-contact-form">

                                            <fieldset :disabled="state.loading">
                                                <div class="form-group">
                                                    <label for="name" >Your Name:</label >
                                                    <input type="text" v-model="state.form.name" class="form-control" :class="{'tw-ring-1 tw-ring-red-900' : v$.form.name.$error}" id="name" name="name" placeholder="Name" @keyup="v$.form.name.$validate()"/>
                                                    <em v-if="v$.form.name.$error" class="tw-text-red-900 tw-text-xs" > {{ v$.form.name.$errors[0].$message }} </em>

                                                </div>
                                                <div class="form-group">
                                                    <label for="mobile_number">Phone Number:</label >
                                                    <input :maxlength="state.maxmobile" v-model="state.form.mobile_number" @input="acceptNumber" type="text" class="form-control" :class="{'tw-ring-1 tw-ring-red-900' : v$.form.mobile_number.$error}" id="mobile_number" name="mobile_number" placeholder="Phone" @keyup="v$.form.mobile_number.$validate()" />
                                                    <em v-if="v$.form.mobile_number.$error" class="tw-text-red-900 tw-text-xs" > {{ v$.form.mobile_number.$errors[0].$message }} </em>


                                                </div>
                                                <div class="form-group">
                                                    <label for="email" >Email address:</label >
                                                    <input type="email" v-model="state.form.email" class="form-control" :class="{'tw-ring-1 tw-ring-red-900' : v$.form.email.$error}" id="email" name="email" placeholder="Email" @keyup="v$.form.email.$validate()" />
                                                    <em v-if="v$.form.email.$error" class="tw-text-red-900 tw-text-xs" > {{ v$.form.email.$errors[0].$message }} </em>

                                                </div>
                                                <div class="form-group">
                                                    <label for="message" >Message:</label >
                                                    <textarea class="form-control" :class="{'tw-ring-1 tw-ring-red-900' : v$.form.message.$error}" v-model="state.form.message" id="message" name="message" cols="30" rows="5" placeholder="Type Your Message" @keyup="v$.form.message.$validate()"></textarea>
                                                    <em v-if="v$.form.message.$error" class="tw-text-red-900 tw-text-xs" > {{ v$.form.message.$errors[0].$message }} </em>

                                                </div>

                                                <button v-if="state.loading" class="tw-border-2 tw-border-maroon_new tw-block tw-h-10 tw-leading-10 tw-w-36 tw-px-4 tw-text-sm tw-no-underline hover:tw-no-underline tw-font-semibold tw-text-maroon_new tw-uppercase tw-mt-5 tw-text-center md:tw-mb-10 tw-cursor-default" disabled> Submit </button>

                                                <button v-else type="submit" class=" tw-border-2 tw-border-maroon_new tw-block tw-h-10 tw-leading-10 tw-w-36 tw-px-4 tw-text-sm tw-no-underline hover:tw-no-underline hover:tw-text-white hover:tw-bg-maroon_new tw-font-semibold tw-text-maroon_new tw-uppercase tw-mt-5 tw-text-center md:tw-mb-10 " @click="submitForm($event)"> Submit </button>
                                            </fieldset>
                                        </form>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="mu-contact-right">
                                        <div class="mu-contact-widget">
                                            <h3>Address:</h3>
                                            <hr class="tw-mb-10 md:tw-mb-5 tw-w-28 " />
                                            <p class="tw-font-semibold"><i class="fas fa-map-marker-alt">&nbsp;&nbsp;</i>Unit 225A/B, Second Floor, Good Earth City Center, Opposite Malibu Town, Sector 50, Gurgaon </p>
                                            <address>
                                                <p> <i class="fas fa-phone"></i >+918527257100 / +911244373829 </p>
                                                <p> <i class="fas fa-envelope" ></i > cocconcurry.corp@gmail.com </p>
                                            </address>

                                            <hr class="tw-my-10 md:tw-mb-5 tw-w-full" />
                                            <p class="tw-font-semibold"><i class="fas fa-map-marker-alt">&nbsp;&nbsp;</i>Sapphire 90 mall, Sector-90, Gurgaon </p>
                                            <address>
                                                <p> <i class="fas fa-phone"></i >+919560103100 / +911247937431 </p>
                                                <p> <i class="fas fa-envelope" ></i > cocconcurry.corp@gmail.com </p>
                                            </address>
                                        </div>

                                        <div class="mu-contact-widget">
                                            <h3>Open Hours:</h3>
                                            <hr class="tw-mb-10 md:tw-mb-5 tw-w-40" />
                                            <address> <p> <span>Monday - Sunday</span> 8:30 AM - 11:00 PM </p></address>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Start Map section -->
        <div class="row">
            <div class="col-lg-6">
                <section class="mu-map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d56151.86378512607!2d76.86645025820313!3d28.404423099999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d3d829cdf4c1d%3A0x8a8bbe423e858c35!2sCocco%20n%20Curry!5e0!3m2!1sen!2sin!4v1654111139563!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </section>
            </div>

             <div class="col-lg-6">
                <section class="mu-map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14035.684216211705!2d77.053188!3d28.4216389!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd50a3972562f017c!2sCocco%20n%20Curry!5e0!3m2!1sen!2sin!4v1658233554512!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </section>
            </div>

        </div>
        <!-- End Map section -->
    </section>
    <Social />
    <!-- <div id="map"></div> -->
</template>

<script>
import { ref } from "vue";
import HeadPic from "../../components/Header/HeadPic";
import Social from "../../components/Social";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength, numeric } from "@vuelidate/validators";
import { reactive, computed } from "vue";

export default {
    name: "Contact",
    components: {
        HeadPic,
        Social,
    },

    setup() {

        const state = reactive({
            sectionTitle: "Contact",
            sectionPicture: "/storage/images/coco/bg_contact.jpg",
            form: {
                name: "",
                mobile_number: "",
                email: "",
                message: "",
            },
            maxmobile: 10,
            authStatus: "",
            authMessage: {
                name: "",
                mobile_number: "",
                email: "",
                message: "",
            },
            loading: false,
            bgPicture: '/storage/images/coco/ajax_loading.gif',
        });

        // Validation Logics
        const rules = computed(() => {
            return {
                form: {
                    name: { required, minLength: minLength(2) },
                    mobile_number: { required, numeric, minLength: minLength(10) },
                    email: { required, email },
                    message: { required },
                }
            };
        });

        const v$ = useVuelidate(rules, state);

        return {
            state,
            v$,
        };
    },

	// validName(name) {
	// 	let validNamePattern = new RegExp("^[a-zA-Z]+(?:[-'\\s][a-zA-Z]+)*$");
	// 	if (validNamePattern.test(name)){
	// 		return true;
	// 	}
	// 	return false;
	// },

    methods: {

        async submitForm(e) {
            e.preventDefault();

            await this.$recaptchaLoaded();

            const token = await this.$recaptcha('login');
            // console.log(token);

            this.v$.$validate();

			if (token) {
                if (!this.v$.$error) {
                    this.state.loading = true;
                    try {
                        const res = await fetch("/api/contact-us/store", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Accept: "application/json",
                            },
                            body: JSON.stringify(this.state.form),
                        });

					    // const result = await res.json();


                        if (res.status === 201 || res.status === 200) {
                            this.state.authStatus = "success";
                            this.state.authMessage = "Thank you for contacting us. We will get in touch with you!";

                            this.state.form.name = null;
                            this.state.form.mobile_number = null;
                            this.state.form.email = null;
                            this.state.form.message = null;

                            this.state.loading = false;

                            this.v$.$reset();
                        } else {
                            this.state.authStatus = "error";
                            this.state.authMessage = "All fields are required!";
                            return;
                        }
                    } catch (error) {
                        this.state.authStatus = "error";
                        this.state.authMessage = "Something went wrong! Try again later.";
                        throw error;
                    }
                } else{
                    this.state.authStatus = "error";
                    this.state.authMessage = "All fields are required!";
                    return;
                }
            } else {
                this.state.authStatus = "error";
                this.state.authMessage = "Something went wrong! Try again later.";
                return;
            }
        },

		validName(name) {
			let validNamePattern = new RegExp("^[a-zA-Z]+(?:[-'\\s][a-zA-Z]+)*$");

			if (validNamePattern.test(name)){
				return true;
			}
			return false;
		},

        acceptNumber() {
            const x = this.state.form.mobile_number.replace(/\D/g, "");
            // console.log('this.mobile_number:', this.mobile_number, 'x:', x);
            this.state.form.mobile_number = x;
        },
    },
};
</script>

<style scoped>

.loader {  /* Loader Div Class */
    position: absolute;
    top:0px;
    right:0px;
    width:100%;
    height:100%;
    background-color:#eceaea;
    /* background-image: url('/storage/images/coco/ajax-loading.gif'); */
    background-size: 50px;
    background-repeat:no-repeat;
    background-position:center;
    z-index:10000000;
    opacity: 0.4;
    filter: alpha(opacity=40);
}

/*==================
 CONTACT SECTION
====================*/

.mu-title h1 {
    font-family: "Kaushan Script", cursive !important;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    color: #88381f;
}

.mu-title p {
    font-size: 26px;
    font-weight: 200;
    margin: 0px;
    font-family: "Viga", sans-serif !important;
    color: #666565;
    text-transform: uppercase;
}

#mu-contact {
    background-color: #f8f8f8;
    display: inline;
    float: left;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 20px;
    padding-bottom: 40px;
    width: 100%;
}

.mu-contact-area {
    display: inline;
    float: left;
    width: 100%;
}

.mu-contact-content {
    display: inline;
    float: left;
    margin-top: 30px;
    width: 100%;
}

.mu-contact-left {
    display: inline;
    float: left;
    font-family: "Raleway", sans-serif !important;
    width: 100%;
    font-weight: 800;
}

.mu-contact-left .form-group {
    margin-bottom: 25px !important;
}

.mu-contact-left .form-group label {
    font-size: 16px !important;
    margin-bottom: 8px !important;
    letter-spacing: 0.5px !important;
}

.mu-contact-form input[type="text"],
.mu-contact-form input[type="email"] {
    color: #000;
    border-radius: 0;
    height: 40px;
}

.mu-contact-form input[type="text"]:focus,
.mu-contact-form input[type="email"]:focus {
    box-shadow: none;
}

.mu-contact-form input[type="text"]:focus,
.mu-contact-form input[type="email"]:focus,
.mu-contact-form textarea:focus {
    border-color: #88381f;
}

.mu-contact-form textarea {
    color: #000;
    border-radius: 0;
    padding: 10px;
}

.mu-contact-form textarea:focus {
    box-shadow: none;
    outline-color: #88381f !important;
}

.mu-contact-right {
    display: inline;
    float: left;
    /* padding: 0 30px; */
    width: 100%;
}

.mu-contact-right h3 {
    font-family: inherit;
    font-weight: 400;
    line-height: 1.1;
    color: #4d4949;
    font-size: 30px;
}

.mu-contact-right p {
    color: #686868;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    line-height: 2;
}

.mu-contact-right .mu-contact-widget {
    display: inline;
    float: left;
    margin-bottom: 25px;
    width: 100%;
}

.mu-contact-right .mu-contact-widget h3 {
    margin-top: 0px;
    padding-bottom: 10px;
    display: inline-block;
}

.mu-contact-right .mu-contact-widget > p {
    letter-spacing: 0.5px;
    line-height: 26px;
}

.mu-contact-right .mu-contact-widget address {
    margin-top: 20px;
}

.mu-contact-right .mu-contact-widget address p {
    letter-spacing: 0.5px;
}

.mu-contact-right .mu-contact-widget address p i {
    margin-right: 10px;
    width: 20px;
    text-align: center;
}

.mu-contact-right .mu-contact-widget address p span {
    display: inline-block;
    margin-right: 20px;
    min-width: 130px;
}

.success {
    padding: 1em;
    margin-bottom: 0.75rem;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    color: #468847;
    background-color: #dff0d8;
    border: 1px solid #d6e9c6;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.error {
    padding: 1em;
    margin-bottom: 0.75rem;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    color: #b94a48;
    background-color: #f2dede;
    border: 1px solid rgba(185, 74, 72, 0.3);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

@media (max-width: 767px) {
    .mu-contact-right {
        padding-top: 30px;
        padding-bottom: 0px;
    }

    #mu-contact {
        padding-bottom: 0px;
    }
}

/*==================
 MAP SECTION
====================*/

.mu-map {
    display: inline;
    float: left;
    height: 450px;
    width: 100%;
    margin: 20px 0 20px 0;
}

.mu-map iframe {
    width: 100%;
    height: 100%;
}
</style>
