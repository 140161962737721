<template>
	<!-- Start Customer Reviews -->
	<div class="customer-reviews-box">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<div class="heading-title text-center">
						<h2>Customer Reviews</h2>
						<hr class="tw-mb-10 md:tw-mb-5 tw-w-96 tw-mx-auto">
						<!-- <p>What Customers Say</p> -->
					</div>
				</div>
			</div>
			<div class="row">
				<div class="mu-gallery-content">
					<div class="mu-gallery-body">

						<!-- start single gallery image -->
						<div class="mu-single-gallery col-md-4 tw-overflow-hidden" v-for="(review, idx) in reviews" :key="idx">
							<div class="mu-single-gallery-item tw-h-48 tw-w-80">
								<figure class="mu-single-gallery-img">
									<video class="tw-mx-auto" width="400" controls :poster="review.data.attributes.thumbnail" @play="pauseAll($event)">
										<source :src="review.data.attributes.src" type="video/mp4">
									</video>
								</figure>
							</div>
						</div>
						<!-- End single gallery image -->

					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- End Customer Reviews -->
</template>

<script>
export default {
    name: "Testimonial",
	components: {

	},
	data() {
		return {
			sectionTitle: "Categories",
			reviews: [],
			vidPlay: false
		};
	},
	methods: {

    async fetchReviews() {
      try {
        const res = await fetch("/api/reviews");
        const result = await res.json();
        return result;
      } catch (err) {
        console.log(err);
      }
    },

	pauseAll(event){

		var videos = document.querySelectorAll('video');

		for(var i=0; i<videos.length; i++){
			//Is this the one we want to play?
			if(videos[i] == event.target) continue;
			//Have we already played it && is it already paused?
			if(videos[i].played.length > 0 && !videos[i].paused){
			// Then pause it now
			videos[i].pause();
			}
		}
	}
  },

  async created() {
    this.reviews = await this.fetchReviews();

	// Other videos pause
	var videos = document.querySelectorAll('video');
	for(var i=0; i<videos.length; i++)
   	videos[i].addEventListener('play', function(){this.pauseAll(this)}, true);
  },
}
</script>

<style scoped>

#mu-gallery {
  display: inline;
  float: left;
  padding: 100px 0;
  width: 100%;
}

.mu-gallery-area {
  display: inline;
  float: left;
  width: 100%;
}

.mu-gallery-content {
  display: inline;
  float: left;
  width: 100%;
}

.mu-gallery-body {
  display: inline;
  float: left;
  width: 100%;
  margin-top: 10px;
}

.mu-single-gallery {
  display: inline;
  float: left;
  margin-bottom: 30px;
  width: 33.33%;
}

.mu-single-gallery:last-child {
  margin-bottom: 0px;
}

.mu-single-gallery .mu-single-gallery-item {
  display: inline;
  float: left;
  width: 100%;
  position: relative;
}

/* .mu-single-gallery .mu-single-gallery-item:hover .mu-single-gallery-info {
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 1;
} */

.mu-single-gallery .mu-single-gallery-item .mu-single-gallery-img {
  display: inline;
  float: left;
  overflow: hidden;
  width: 100%;
}

.mu-single-gallery .mu-single-gallery-item .mu-single-gallery-img img {

  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -ms-transition: all 0.8s;
  -o-transition: all 0.8s;
  transition: all 0.8s;
}

.mu-single-gallery .mu-single-gallery-item .mu-single-gallery-info {
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  padding-top: 28%;
  opacity: 0;
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -ms-transition: all 0.8s;
  -o-transition: all 0.8s;
  transition: all 0.8s;
}

.block-title {
  font-family: "Kaushan Script", cursive !important;
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  color: #88381f;
}

.about-images {
  text-align: center;
  position: relative;
  /* top: 50px; */
}

/* .about-main {
  padding: 20px 0px;
} */

.about-main h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 20px;
  padding: 40px 0px;
}

.about-main p {
  color: #686868;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  line-height: 2;
}

.left-btn {
  padding-top: 10px;
}

/* .about-images img {
    border: 10px solid #f1f1f1;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
} */

.about-images img {
  border: 10px solid #f1f1f1;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 100%;
}

.about-inset {
  position: absolute;
  top: 0;
  right: 0;
}

@media (max-width: 767px) {
  .block-title {
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    color: #88381f;
  }

  .about-images {
    top: 20px;
  }

  .mu-single-gallery {
    display: block;
    float: left;
    margin-bottom: 30px;
    width: 100%;
  }
}


/*------------------------------------------------------------------
    Customer Reviews
-------------------------------------------------------------------*/

.heading-title h2{
	font-family: 'Kaushan Script', cursive !important;
	font-size: 50px;
	font-weight: bold;
	color: #88381f;
	text-transform: capitalize ;
}
.heading-title p{
	font-size: 26px;
	font-weight: 200;
	margin: 0px;
	font-family: 'Viga', sans-serif !important;
	color: #666565;
	text-transform: uppercase;
}

.carousel-item p {
	color: #686868;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    line-height: 2;
	text-align: start;
}

.customer-reviews-box{
	padding: 50px 0px;
}

.carousel-inner .carousel-item .img-box{
	width: 135px;
	height: 135px;
}
.carousel-control-prev{
	left: -100px;
}
.carousel-control-next{
	right: -100px;
}
.carousel-indicators{
	top: 320px;
}

.text-warning{
	color: #88381f !important;
	font-size: 24px;
}

#reviews .carousel-control-prev{
	background: #88381f;
	color: #ffffff;
	padding: 4px;
	display: block;
	position: absolute;
	width: 8%;
	height: 50px;
	top: 50%;
	border-radius: 4px;
	font-size: 28px;
	opacity: 1;
}
#reviews .carousel-control-prev:hover{
	background: #a15c47;
	color: #ffffff;
}

#reviews .carousel-control-next{
	background: #88381f;
	color: #ffffff;
	padding: 4px;
	display: block;
	position: absolute;
	width: 8%;
	height: 50px;
	top: 50%;
	border-radius: 4px;
	font-size: 28px;
	opacity: 1;
}
#reviews .carousel-control-next:hover{
	background: #a15c47;
	color: #ffffff;
}

@media (min-width: 320px) and (max-width: 640px) {
	.carousel-inner .carousel-item p{
		font-size: 14px;
	}
	.carousel-control-prev{
		left: -5px;
	}
	.carousel-control-next{
		right: -5px;
	}
 	.carousel-indicators{
		top: 400px;
	}
}

@media (max-width: 767px) {
	.heading-title h2{
		font-size: 40px !important;
		margin-top: 0px;
	}

	.customer-reviews-box{
		padding-top: 20px;
	}
}

</style>


