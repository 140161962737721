<template>
    <div class="container tw-text-center tw-pt-32 lg:tw-pt-20 error-main">
        <h1 class='error-title tw-text-maroon_new'>404</h1>
        <p class="error-des">We couldn't find the page you are looking for</p>
        <router-link to="/" class=" tw-mx-auto tw-border-2 tw-border-maroon_new tw-block tw-h-10 tw-leading-10 tw-w-36 tw-px-4 tw-text-sm tw-no-underline hover:tw-no-underline hover:tw-text-white hover:tw-bg-maroon_new tw-font-semibold tw-text-maroon_new tw-uppercase tw-mt-5 tw-text-center tw-mb-10">Go Home</router-link>
    </div>

  <Social />

</template>

<script>
import Social from '../../../components/Social';
export default {
  name: "Error404",
  components: {
      Social,
  },
};
</script>

<style scoped>

.error-title {
    font-size: 10rem;
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
}

.error-des {
 color: #727E8C;
}

.error-main {
    /* height: 100vh; */
    height: calc(100vh - 220px);
}

@media screen and (max-width: 768px) {
    .error-title {
        font-size: 8rem;
    }
}

/* @media screen and (max-width: 768px) {
    .error-main {
        height: 624px;
    }
} */

</style>
