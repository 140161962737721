import { createApp } from "vue";
import App from "./components/App";
import router from "./router";
import VueEasyLightbox from 'vue-easy-lightbox';
import VueLazyLoad from 'vue3-lazyload';
import VueGtag from "vue-gtag-next";
import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3';

require('./bootstrap');

let app = createApp(App);

app.use(router);

app.use(VueReCaptcha, {
  siteKey: '6LfiCLYdAAAAABkQYPAVFGTRp_LI-bW6Thqs72Z8',
  loaderOptions: {
    useRecaptchaNet: true
  }
});

app.use(VueGtag, {
  property: {
    id: "G-80NZET17EF"
  },
  router
});

app.use(VueLazyLoad);
app.use(VueEasyLightbox);
app.mount("#app");