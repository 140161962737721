import { createRouter, createWebHistory } from "vue-router";

import Home from "./pages/Frontend/Home";
import AboutUs from "./pages/Frontend/AboutUs";
import Menu from "./pages/Frontend/Menu";
import Ambience from "./pages/Frontend/Gallery/Ambience";
import Celebration from "./pages/Frontend/Gallery/Celebration";
import Dishes from "./pages/Frontend/Gallery/Dishes";
import Contact from "./pages/Frontend/Contact";
import Franchise from "./pages/Frontend/Franchise";
import Reviews from "./pages/Frontend/Reviews";
import Error404 from "./pages/Frontend/Errors/Error404";

// import store from "./store";

const routes = [
    {
        path: "/", name: "Home", component: Home,
        meta: {
            breadCrumb: {
                to: '/',
                text: 'home'
            }
        },
    },

    {
        path: "/about-us", name: "AboutUs", component: AboutUs,
        meta: {
            breadCrumb: {
                to: '/about-us',
                text: 'aboutUs'
            }
        },
    },

    {
        path: "/menu", name: "Menu", component: Menu,
        meta: {
            breadCrumb: {
                to: '/menu',
                text: 'menu'
            }
        },
    },

    // {
    //     path: "/gallery", name: "Gallery", component: Gallery,
    //     meta: {
    //         breadCrumb: {
    //             to: '/gallery',
    //             text: 'gallery'
    //         }
    //     },
    // },

    {
        path: "/gallery/celebration", name: "Celebration", component: Celebration,
        meta: {
            breadCrumb: {
                to: '/gallery/celebration',
                text: 'celebration'
            }
        },
    },

    {
        path: "/gallery/ambience", name: "Ambience", component: Ambience,
        meta: {
            breadCrumb: {
                to: '/gallery/ambience',
                text: 'ambience'
            }
        },
    },

    {
        path: "/gallery/dishes", name: "Dishes", component: Dishes,
        meta: {
            breadCrumb: {
                to: '/gallery/dishes',
                text: 'dishes'
            }
        },
    },

    {
        path: "/contact-us", name: "Contact", component: Contact,
        meta: {
            breadCrumb: {
                to: '/contact-us',
                text: 'contact',
            },
        },
    },

    {
        path: "/what-customers-say", name: "Reviews", component: Reviews,
        meta: {
            breadCrumb: {
                to: '/what-customers-say',
                text: 'reviews',
            },
        },
    },

    {
        path: "/franchise", name: "Franchise", component: Franchise,
        meta: {
            breadCrumb: {
                to: '/franchise',
                text: 'about',
            },
        },
    },

    {
        path: "/:catchAll(.*)", name: "Error404", component: Error404,
        meta: {
            breadCrumb: {
                to: '/error-404',
                text: 'Error 404',
            },
        },
    },
];

const router = createRouter({
    // history: createWebHistory(process.env.BASE_URL),
    history: createWebHistory(),
    routes,
});

export default router;
