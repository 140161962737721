<template>
  <!-- Start Menu -->
	<div class="special-menu tw-bg-center tw-bg-cover tw-bg-no-repeat" :style="{'background-image':'url(/storage/images/coco/background.jpeg)', 'width': '100%'}">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="">
                        <h2 class="tw-text-yellow-600 block-title"> Featured Menu </h2>
						<hr class="tw-mb-10 md:tw-mb-5 tw-bg-gray-500 tw-w-80 tw-mx-auto">
                        <h5 class="title-caption"> Some of our customer's favourite and our best, made from fresh ingredients. </h5>
                    </div>

					<div class="special-box md:tw-block tw-hidden">

						<Carousel :autoplay="5000" :items-to-show="1" :wrap-around="true" transition="2000">
							<Slide v-for="img in sliderImgs" :key="img">
							<div class="carousel__item">
								<img :src="'/storage/images/coco/'+img" alt="">
							</div>
							</Slide>

							<template #addons>
							<Navigation />
							</template>
						</Carousel>

                    </div>

                    <div class="special-box md:tw-hidden">

						<Carousel :autoplay="5000" :items-to-show="3" :wrap-around="true" transition="2000">
							<Slide v-for="img in sliderImgs" :key="img">
							<div class="carousel__item">
								<img :src="'/storage/images/coco/'+img" alt="">
							</div>
							</Slide>

							<template #addons>
							<Navigation />
							</template>
						</Carousel>

                    </div>

                </div>
                <!-- end col -->
            </div>
            <!-- end row -->
        </div>
        <!-- end container -->
    </div>
	<!-- End Menu -->
</template>

<script>

import { defineComponent } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';

export default defineComponent({
    name: "SpecialMenu",
	components: {
	Carousel,
    Slide,
    Navigation,
	},

	data() {
	  return {
		  sliderImgs: [
					"image0.jpg",
					"image1.jpg",
					"image2.jpg",
					"image3.jpg",
					"image4.jpg",
				],
	  }
  },
});
</script>

<style scoped>

/*------------------------------------------------------------------
    SPECIAL-MENU AREA
-------------------------------------------------------------------*/

:root {
  /* Colors */
  --carousel-color-primary: #88381f;
  --carousel-color-secondary: #8e98f3;
  --carousel-color-white: #ffffff;

  /* Navigation */
  --carousel-nav-width: 30px;
}

.special-menu{
	/* background-image: no-repeat center !important; */
	background-attachment:fixed;
	background-size:cover;
	position:relative;
	overflow: hidden;
	padding: 50px 0px;
}

.special-menu:before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.67);
    position: absolute;
    top: 0;
    left: 0;
}

.title-caption {
    letter-spacing: 0.16px;
    padding: 20px;
	color:#ffffff;
	font-size: 18px;
	text-align: center;
}

.block-title{
	font-family: 'Kaushan Script', cursive !important;
	font-size:50px;
	font-weight: bold;
	text-align: center;
}

/* .pad-top-70{
    padding-top: 70px;
} */

#owl-demo .item{
  margin: 15px;
  position:relative;
}
#owl-demo .item img{
  display: block;
  width: 100%;
  height: auto;
}
.special-box .owl-theme .owl-controls{
	margin:0px;
}

.item-type-zoom{
	border:4px solid #fff;
}
.item-type-zoom .item-hover {
    z-index: 5;
    -webkit-transition: all 300ms ease-out;
    -moz-transition: all 300ms ease-out;
    -o-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    opacity: 0;
    cursor: pointer;
    display: block;
    text-decoration: none;
    text-align: center;
}

.item-type-zoom .item-info {
    z-index: 10;
    color: #ffffff;
    display: table-cell;
    vertical-align: middle;
    position: absolute;
    z-index: 5;
    -webkit-transform: scale(0,0);
    -moz-transform: scale(0,0);
    -ms-transform: scale(0,0);
    transform: scale(0,0);
    -webkit-transition: all 300ms ease-out;
    -moz-transition: all 300ms ease-out;
    -o-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
	bottom:0px;
}

.item-type-zoom .item-info .headline {
    font-size: 18px;
    width: 75%;
    margin: 0 auto;
    border: 4px solid #ffffff;
    padding: 10px;
}

.item-type-zoom .item-info .line {
    height: 1px;
    width: 60%;
    margin: 15px auto 10px auto;
    background-color: #ffffff;
}

.item-type-zoom .item-info .dit-line {
    font-size: 14px;
    font-style: italic;
}

.item-hover, .item-hover {
	position: absolute;
    top: 0;
    left: 0;
	height:100%;
	width:100%;
}
.item-type-zoom .item-hover:hover {
    opacity: 1;
}

.item-type-zoom .item-hover:hover:before{
	content:"";
	position:absolute;
	height:100%;
	width:100%;
	background:rgba(231, 91, 30, 0.5);
	top:0px;
	left:0px;
}

.item-type-zoom .item-hover:hover .item-info {
    -webkit-transform: scale(1,1);
    -moz-transform: scale(1,1);
    -ms-transform: scale(1,1);
    transform: scale(1,1);
}

.special-box{
	padding-bottom:40px;
}


/* .carousel__slide > .carousel__item {
  transform: scale(1);
  opacity: 0.5;
  transition: 1s;
}
.carousel__slide--visible > .carousel__item {
  opacity: 1;
  transform: rotateY(0);
}
.carousel__slide--next > .carousel__item {
  transform: scale(0.9) translate(-10px);
}
.carousel__slide--prev > .carousel__item {
  transform: scale(0.9) translate(10px);
}
.carousel__slide--active > .carousel__item {
  transform: scale(1.1);
} */


.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: #8a7777;
  color:  var(--carousel-color-white);
  font-size: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 10px double #8a7777 ;
}

.carousel__item img {
	border-radius: 10px;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  background-color: #88381f;
  border-radius: var(--carousel-nav-width);
  width: var(--carousel-nav-width);
  height: var(--carousel-nav-width);
  text-align: center;
  font-size: calc(var(--carousel-nav-width) * 2 / 3);
  padding: 0;
  color: var(--carousel-color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border: 0;
  cursor: pointer;
}

.carousel__prev {
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
}

.carousel__next {
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
}

@media (max-width: 767px) {
	.block-title{
		font-size: 40px !important;
		margin-top: 0px;
	}

	.special-menu{
		padding: 20px 0px;
	}
}

</style>
