// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lightgallery.css);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-zoom.css);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "*[data-v-787eccbb]{background:#f8f8f8!important;box-sizing:border-box;margin:0;padding:0}", "",{"version":3,"sources":["webpack://./resources/js/components/App.vue"],"names":[],"mappings":"AAmCA,mBAKE,4BAA8B,CAF9B,qBAAsB,CAFtB,QAAS,CACT,SAIF","sourcesContent":["<template>\n    <div class=\"tw-flex tw-flex-col tw-overflow-y-hidden\">\n        <Navbar />\n\n        <router-view></router-view>\n\n    <Footer />\n\n   </div>\n</template>\n\n<script>\nimport Navbar from \"./Header/Navbar\";\nimport Footer from \"./Footer\";\nexport default {\n  name: \"App\",\n  components: {\n      Navbar,\n      Footer,\n   },\n\n   methods: {\n\n   },\n\n   created() {\n\n   }\n};\n</script>\n\n<style scoped>\n@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lightgallery.css');\n@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-zoom.css');\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  /* background: #e9ecef !important; */\n  background: #f8f8f8 !important;\n}\n\n\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
