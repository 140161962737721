<template>
  <!-- Start header -->
	<header class="top-navbar">
		<nav class="navbar navbar-expand-xl navbar-light tw-shadow-md tw-max-h-20">
			<div class="container-fluid tw-px-10 tw-py-5">
				<router-link tag="a" class="navbar-brand tw-z-60 tw-overflow-hidden" to="/">
					<img :src="'/images/logo/'+logo" class="tw-absolute tw-top-0.5 tw-object-cover tw-z-40 md:tw-h-11" :style="{ 'height' : '76px' }" alt="logo" />
				</router-link>
				<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbars-rs-food" aria-controls="navbars-rs-food" aria-expanded="false" aria-label="Toggle navigation">
				  <span class="navbar-toggler-icon"></span>
				</button>
				<div class="collapse navbar-collapse" id="navbars-rs-food">
					<ul class="navbar-nav ml-auto tw-uppercase tw-z-100 xl:tw-pt-5">
						<li class="nav-item" :class="$route.path === '/' ? 'active' : ''">
							<router-link tag="a" class="nav-link" to="/">Home</router-link>
						</li>
						<li class="nav-item" :class="$route.path === '/about-us' ? 'active' : ''">
							<router-link tag="a" class="nav-link" to="/about-us">About Us</router-link>
						</li>
						<li class="nav-item" :class="$route.path === '/menu' ? 'active' : ''">
							<router-link tag="a" class="nav-link" to="/menu">Menu</router-link>
						</li>

						<li class="nav-item" :class="$route.path === '/what-customers-say' ? 'active' : ''">
							<router-link tag="a" class="nav-link" to="/what-customers-say">Customers Say</router-link>
						</li>

						<li class="nav-item dropdown">
							<a class="nav-link dropdown-toggle" :class="$route.path === '/gallery/celebration' || $route.path === '/gallery/ambience' || $route.path === '/gallery/dishes' ? 'active' : ''" href="javascript:void()" id="dropdown-a" data-toggle="dropdown" >Gallery</a>
							<div class="dropdown-menu" aria-labelledby="dropdown-a">								
								<router-link tag="a" class="dropdown-item" to="/gallery/dishes" :class="$route.path === '/gallery/dishes' ? 'active' : ''">Dishes</router-link>
								<router-link tag="a" class="dropdown-item" to="/gallery/ambience" :class="$route.path === '/gallery/ambience' ? 'active' : ''">Ambience</router-link>
								<router-link tag="a" class="dropdown-item" to="/gallery/celebration" :class="$route.path === '/gallery/celebration' ? 'active' : ''">Celebration</router-link>
							</div>
						</li>
						<li class="nav-item" :class="$route.path === '/contact-us' ? 'active' : ''">
							<router-link tag="a" class="nav-link" to="/contact-us">Contact Us</router-link>
						</li>						
						
						<li class="nav-item" :class="$route.path === '/franchise' ? 'active' : ''">
							<router-link tag="a" class="nav-link" to="/franchise">Franchise</router-link>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	</header>
	<!-- End header -->
</template>

<script>
export default {
  name: "Navbar",
  data() {
	  return {
		logo:"logomini.png"
	}
  }  
};
</script>

<style scoped>

/*------------------------------------------------------------------
    HEADER
-------------------------------------------------------------------*/
.top-navbar .navbar{
	padding: 15px 0px;
	position: fixed;
	width: 100%;
	transition: height .3s ease-out, background .3s ease-out, box-shadow .3s ease-out;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	z-index: 100;
	background: #ffffff !important;
}

.top-navbar .navbar-light .navbar-nav {
	background: #ffffff !important;
}

.top-navbar .navbar-light .navbar-nav .nav-link{
	color: #010101;
	font-size: 15px;
	font-weight: 700;
	letter-spacing: 2px;
	padding: 5px 10px;
	border-radius: 4px;
	margin-left: 10px;
	font-family: 'Roboto Condensed', sans-serif;
	
}

.top-navbar .navbar-light .navbar-nav .nav-item .nav-link:hover{
	background: #88381f;
	color: #ffffff;
}
.top-navbar .navbar-light .navbar-nav .nav-item.active .nav-link{
	background: #88381f;
	color: #ffffff;
	border-radius: 4px;
}

.top-navbar .navbar-light .navbar-nav .nav-item .nav-link.active {
	background: #88381f;
	color: #ffffff;
	border-radius: 4px;
}

.navbar-expand-xl .navbar-nav .dropdown-menu a.dropdown-item.active{
	background: #88381f;
	color: #ffffff;
}

/* .top-navbar .navbar-light .navbar-nav .nav-item.active router-link.dropdown-item{
	background: #88381f;
	color: #ffffff;
	border-radius: 4px;
} */

.navbar-expand-lg .navbar-nav .dropdown-menu{
	border: none;
	letter-spacing: 2px;
	 box-shadow: 2px 5px 6px rgba(0,0,0,0.5);
	 font-family: 'Roboto Condensed', serif;
}

.navbar-expand-xl .navbar-nav .dropdown-menu a.dropdown-item:hover{
	background: #88381f;
	color: #ffffff;
}
.navbar-light .navbar-toggler:hover{
	background: #cfa671;
}

/* @media (max-width: 1280) {
	.navbar-light .navbar-brand{
		margin-left: 15px;
	}
	.navbar-light .navbar-toggler{
		margin-right: 15px;
		margin-top: -10px;
		border-radius: 0px;
	}
	.navbar-expand-xl .navbar-nav .dropdown-menu{
		box-shadow: none;
	}

	.top-navbar .navbar-light .navbar-nav .nav-link{
		margin-left: 0;
	}
} */

/* mobile or only mobile */
@media (max-width: 1210px) {
	.navbar-light .navbar-brand{
		margin-left: 15px;
	}
	.navbar-light .navbar-toggler{
		margin-right: 15px;
		margin-top: -10px;
		border-radius: 0px;
	}
	.navbar-expand-xl .navbar-nav .dropdown-menu{
		box-shadow: none;
	}

	.top-navbar .navbar-light .navbar-nav .nav-link{
		margin-left: 0;
	}
}

</style>