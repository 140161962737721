<template>
  <HeadPic :title="sectionTitle" :picture="sectionPicture" />

  <div id="about" class="about-main tw-pt-5 tw-pb-10">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="block-title">Our Dishes</h2>
          <hr class="tw-mb-10 md:tw-mb-5 tw-w-52 tw-mx-auto" />
        </div>

        <div class="col-12">

          <div class="row">
            
            <div class="mu-gallery-content">
              <div class="mu-gallery-body">

                <!-- start single gallery image -->
                  <div class="mu-single-gallery col-md-4 tw-overflow-hidden" v-for="(img, idx) in dishesImages" :key="idx" @click.prevent="showMultiple(idx)">
                      <div class="mu-single-gallery-item tw-h-48 tw-w-80">
                        <figure class="mu-single-gallery-img">
                          <a class="mu-imglink" :href="img">
                            <img alt="img" :src="img">
                          </a>
                        </figure>            
                      </div>
                  </div>
                  <!-- End single gallery image -->

              </div>
            </div>

            <div class="col-12">
              <!-- all props & events -->
              <vue-easy-lightbox
                scrollDisabled
                escDisabled
                moveDisabled
                :visible="visible"
                :imgs="imgs"
                :index="index"
                @hide="handleHide"
              ></vue-easy-lightbox>
            </div>
            
          </div>

        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </div>
  <Social />
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'
import HeadPic from "../../../components/Header/HeadPic";
import Social from '../../../components/Social';
export default {
  name: "Dishes",
  components: {
    HeadPic,
    VueEasyLightbox,
    Social,  
  },
  data() {
    return {
      sectionTitle: "Dishes",
      sectionPicture: "/storage/images/coco/bg_dishes.jpg",
      dishesImages: [],
      index: 0, // default: 0
      visible: false,
      imgs: this.dishesImages
    };
  },
  methods: {
    async fetchDishesImages() {
      try {
        const res = await fetch("/api/gallery/3");
        const result = await res.json();
        return result;
      } catch (err) {
        console.log(err);
      }
    },  

    showMultiple(idx) {
      this.imgs = this.dishesImages;
      // allow mixing

      this.index = idx // index of imgList
      this.show()
    },
    show() {
      this.visible = true
    },
    handleHide() {
      this.visible = false
    }
  },

  async created() {
    this.dishesImages = await this.fetchDishesImages();
  },
};
</script>

<style scoped>

#mu-gallery {
  display: inline;
  float: left;
  padding: 100px 0;
  width: 100%;
}

.mu-gallery-area {
  display: inline;
  float: left;
  width: 100%;
}

.mu-gallery-content {
  display: inline;
  float: left;
  width: 100%;
}

.mu-gallery-body {
  display: inline;
  float: left;
  width: 100%;
  margin-top: 10px;
}

.mu-single-gallery {
  display: inline;
  float: left;
  margin-bottom: 30px;
  width: 33.33%;
}

.mu-single-gallery:last-child {
  margin-bottom: 0px;
}

.mu-single-gallery .mu-single-gallery-item {
  display: inline;
  float: left;
  width: 100%;
  position: relative;
}

/* .mu-single-gallery .mu-single-gallery-item:hover .mu-single-gallery-info {
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 1;
} */

.mu-single-gallery .mu-single-gallery-item .mu-single-gallery-img {
  display: inline;
  float: left;
  overflow: hidden;
  width: 100%;
}

.mu-single-gallery .mu-single-gallery-item .mu-single-gallery-img img {
  
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -ms-transition: all 0.8s;
  -o-transition: all 0.8s;
  transition: all 0.8s;
}

.mu-single-gallery .mu-single-gallery-item .mu-single-gallery-info {
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  padding-top: 28%;
  opacity: 0;
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -ms-transition: all 0.8s;
  -o-transition: all 0.8s;
  transition: all 0.8s;
}

.block-title {
  font-family: "Kaushan Script", cursive !important;
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  color: #88381f;
}

.about-images {
  text-align: center;
  position: relative;
  /* top: 50px; */
}

/* .about-main {
  padding: 20px 0px;
} */

.about-main h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 20px;
  padding: 40px 0px;
}

.about-main p {
  color: #686868;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  line-height: 2;
}

.left-btn {
  padding-top: 10px;
}

/* .about-images img {
    border: 10px solid #f1f1f1;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
} */

.about-images img {
  border: 10px solid #f1f1f1;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 100%;
}

.about-inset {
  position: absolute;
  top: 0;
  right: 0;
}

@media (max-width: 767px) {
  .block-title {
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    color: #88381f;
  }

  .about-images {
    top: 20px;
  }

  .mu-single-gallery {
    display: block;
    float: left;
    margin-bottom: 30px;
    width: 100%;
  }
}

</style>