<template>
  <!-- Start All Pages -->
  <div class="all-page-title page-breadcrumb" :style="{'background-image':'url('+picture+')', 'width': '100%'}">
    <div class="container text-center">
      <div class="row">
        <div class="col-lg-12">
          <h1>{{ title }}</h1>
        </div>
      </div>
    </div>
  </div>
    <!-- End All Pages -->
</template>

<script>
export default {
    name:"HeadPic",
    props:{
      title: String,
      picture: String,
    }
}
</script>

<style scoped>
/*------------------------------------------------------------------
    All Pages
-------------------------------------------------------------------*/

.page-breadcrumb {
    padding: 250px 0 150px;
    /* background: url(../images/all-bg.jpg) no-repeat; */
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    position: relative;
}
.all-page-title .container{
	position: relative;
	z-index: 2;
}
.all-page-title::before{
	background: #010101;
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
	opacity: 0.5;	
}
.all-page-title h1{
	font-size: 38px;
	color: #ffffff;
	font-family: 'Viga', sans-serif !important; 
	padding: 0px;
	font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}
</style>