<template>
    <div>
      <Slider />
      <Welcome />
      <SpecialMenu />
      <Testimonial />
      <Social />
    </div>

</template>

<script>
import Slider from '../../components/Slider';
import Welcome from '../../components/Welcome';
import Testimonial from '../../components/Testimonial';
import SpecialMenu from '../../components/SpecialMenu';
import Social from '../../components/Social';
export default {
    name: "Home",
    components: {
      Slider,
      Welcome,
      Testimonial,
      SpecialMenu,
      Social
    },
}
</script>

<style>

.fbnewicon {
  border-radius: 4px;
  top: 385px;
  position: fixed;
  right: -64px;
  z-index: 150;
  transition: ease-in-out .6s;
  -webkit-transition: ease-in-out .6s;
  background-color: #166fe5;
  color: #ffffff;
  text-decoration: none;
}
.fbnew:hover {
  color: #ffffff;
  text-decoration: none;
}

.fbnewicon:hover {
  right: -5px;
}

.instanewicon {
  border-radius: 4px;
  top: 430px;
  position: fixed;
  right: -65px;
  z-index: 150;
  transition: ease-in-out .6s;
  -webkit-transition: ease-in-out .6s;
  background-color: #C13584;
  color: #ffffff;
  text-decoration: none;
}

.instanew:hover {
  color: #ffffff;
  text-decoration: none;
}

.instanewicon:hover {
  right: -5px;
}

</style>
