<template>
    <HeadPic :title="state.sectionTitle" :picture="state.sectionPicture" />

    <section id="mu-contact">        

        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="mu-contact-area">
                        <div class="mu-title tw-text-center">
                            <h1 class="mu-subtitle">Franchise Enquiry</h1>
                            <hr class="tw-mb-10 md:tw-mb-5 tw-w-96 tw-mx-auto" />
                            <p>PARTNER WITH US</p>
                        </div>

                        <div class="mu-contact-content">
                            <div class="row">
                                

                                 

                                <div class="col-md-8 mx-auto tw-border tw-border-gray-200 tw-p-5 tw-shadow tw-rounded-md">

                                   

                                    <div class="mu-contact-left tw-relative">

                                        <div v-if="state.loading" class="tw-flex tw-justify-center tw-items-center tw-absolute tw-text-center tw-w-full" style="min-height:820px">
                                            <div class="spinner-grow inline-block tw-w-10 tw-h-10 bg-current rounded-full opacity-0 tw-text-maroon_new" role="status">
                                                <span class="visually-hidden"></span>
                                            </div>
                                        </div>
                                        <!-- Email message div -->
                                        <!-- <div id="form-messages"></div> -->
                                         <!-- <div class=" alert alert-danger tw-text-center" v-if="state.authStatus === 'error'" >
                                            {{ state.authMessage }}
                                        </div> -->

                                        <div class="alert alert-success tw-text-center" v-if="state.authStatus === 'success'">
                                            {{ state.authMessage }}
                                        </div> 

                                        <!-- <div v-else-if="state.authStatus === ''"></div>  -->
                                        <!-- Start contact form -->
                                        <p class=" tw-text-sm tw-text-gray-500 tw-italic" > *Please fill the below form for franchise queries! </p>

                                        <br />

                                        <form id="ajax-contact" method="post" class="mu-contact-form">

                                            <fieldset :disabled="state.loading">
                                                <div class="form-group">
                                                    <label for="name">Name:</label>
                                                    <input type="text" class="form-control" :class="{ 'tw-ring-1 tw-ring-red-900': v$.form.name.$error }" v-model="state.form.name" placeholder="Name" required>
                                                    <em v-if="v$.form.name.$error" class=" tw-text-red-900 tw-text-xs " > {{ v$.form.name.$errors[0].$message }} </em>
                                                </div>
                                                <div class="form-group">
                                                    <label for="mobile_number" >Mobile Number:</label >
                                                    <input :maxlength="state.maxmobile" v-model=" state.form.mobile_number " @input="acceptNumber" type="text" class="form-control" :class="{ 'tw-ring-1 tw-ring-red-900': v$.form.mobile_number.$error }" name="mobile_number" placeholder="Phone" required>
                                                    <em v-if="v$.form.mobile_number.$error" class=" tw-text-red-900 tw-text-xs " > {{ v$.form.mobile_number.$errors[0].$message }} </em>
                                                </div>
                                                <div class="form-group">
                                                    <label for="email" >Email ID:</label >
                                                    <input type="email" class="form-control" :class="{ 'tw-ring-1 tw-ring-red-900': v$.form.email.$error }" v-model="state.form.email" placeholder="Email" required>
                                                    <em v-if="v$.form.email.$error" class=" tw-text-red-900 tw-text-xs " > {{ v$.form.email.$errors[0].$message }} </em>
                                                </div>
                                                <div class="form-group">
                                                    <label for="address" >Address <span class=" tw-text-base tw-italic tw-text-gray-500 tw-font-normal " >(Optional)</span >&nbsp;:</label >
                                                    <textarea class="form-control" v-model="state.form.address" cols="30" rows="5" placeholder="Address" required></textarea>
                                                </div>
                                                <div class="form-group">
                                                    <label for="proposed_location" >Proposed Location:</label >
                                                    <input type="text" class="form-control" :class="{ 'tw-ring-1 tw-ring-red-900': v$.form.proposed_location.$error }" v-model=" state.form.proposed_location " placeholder="Proposed Location" required>
                                                    <em v-if="v$.form.proposed_location.$error" class="tw-text-red-900 tw-text-xs " > {{ v$.form.proposed_location.$errors[0].$message }} </em>
                                                </div>

                                                <div class="form-group">
                                                    <label for="comments" >Comments <span class=" tw-text-base tw-italic tw-text-gray-500 tw-font-normal " >(Optional)</span >&nbsp;:</label >
                                                    <textarea class="form-control" v-model=" state.form.comments " cols="30" rows="5" placeholder="Comments" required></textarea>
                                                </div>

                                                <button v-if="state.loading" class="tw-border-2 tw-border-maroon_new tw-block tw-h-10 tw-leading-10 tw-w-36 tw-px-4 tw-text-sm tw-no-underline hover:tw-no-underline tw-font-semibold tw-text-maroon_new tw-uppercase tw-mt-5 tw-text-center md:tw-mb-10 tw-cursor-default" disabled> Submit </button>

                                                <button v-else type="submit" class=" tw-border-2 tw-border-maroon_new tw-block tw-h-10 tw-leading-10 tw-w-36 tw-px-4 tw-text-sm tw-no-underline hover:tw-no-underline hover:tw-text-white hover:tw-bg-maroon_new tw-font-semibold tw-text-maroon_new tw-uppercase tw-mt-5 tw-text-center md:tw-mb-10 " @click="submitForm($event)"> Submit </button>
                                            </fieldset>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Social />
</template>

<script>
// import Recaptcha from "../../components/Recaptcha";
import HeadPic from "../../components/Header/HeadPic";
import Social from "../../components/Social";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength, numeric } from "@vuelidate/validators";
import { reactive, computed, onUpdated } from "vue";
export default {
    name: "Franchise",
    components: {
        HeadPic,
        Social,
        // Recaptcha,
    },

    setup() {
        const state = reactive({
            sectionTitle: "Franchise",
            sectionPicture: "/storage/images/coco/bg_franchise.jpg",
            form: {
                name: "",
                mobile_number: "",
                email: "",
                address: "",
                proposed_location: "",
                comments: "",
            },
            maxmobile: 10,
            authStatus: "",
            authMessage: {
                name: "",
                mobile_number: "",
                email: "",
                proposed_location: "",
            },
            loading: false,
            bgPicture: '/storage/images/coco/ajax_loading.gif',
        });

        // Validation Logics
        const rules = computed(() => {
            return {
                form: {
                    name: { required, minLength: minLength(2) },
                    mobile_number: {
                        required,
                        numeric,
                        minLength: minLength(10),
                    },
                    email: { required, email },
                    proposed_location: { required },
                },
            };
        });

        const v$ = useVuelidate(rules, state);

        return {
            state,
            v$,
        };
    },

    methods: {
        async submitForm(e) {
            e.preventDefault();

            await this.$recaptchaLoaded();
            const token = await this.$recaptcha("login");
            // console.log(token);
            this.v$.$validate();

            if (token) {
                if (!this.v$.$error) {
                    this.state.loading = true;
                    try {
                        const res = await fetch(
                            "/api/franchise-enquiry/store",
                            {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Accept: "application/json",
                                },
                                body: JSON.stringify(this.state.form),
                            }
                        );

                        if (res.status === 201 || res.status === 200) {
                            this.state.authStatus = "success";
                            this.state.authMessage ="Thank you for contacting us. We will get in touch with you!";

                            this.state.form.name = null;
                            this.state.form.mobile_number = null;
                            this.state.form.email = null;
                            this.state.form.proposed_location = null;
                            this.state.form.address = null;
                            this.state.form.comments = null;   
                            
                            this.state.loading = false;

                            this.v$.$reset();
                        } else {
                            this.state.authStatus = "error";
                            this.state.authMessage = "All fields are required!";
                            return;
                        }
                    } catch (error) {
                        this.state.authStatus = "error";
                        this.state.authMessage = "Something went wrong! Try again later.";
                        throw error;
                    }
                } else {
                    this.state.authStatus = "error";
                    this.state.authMessage = "All fields are required!";
                    return;
                }
            } else {
                this.state.authStatus = "error";
                this.state.authMessage = "Something went wrong! Try again later.";
                return;
            }
        },

        acceptNumber() {
            const x = this.state.form.mobile_number.replace(/\D/g, "");
            // console.log('this.mobile_number:', this.mobile_number, 'x:', x);
            this.state.form.mobile_number = x;
        },
    },
};
</script>

<style scoped>

.loader {  /* Loader Div Class */
    position: absolute;
    top:0px;
    right:0px;
    width:100%;
    height:100%;
    background-color:#eceaea;
    /* background-image: url('/storage/images/coco/ajax-loading.gif'); */
    background-size: 50px;
    background-repeat:no-repeat;
    background-position:center;
    z-index:10000000;
    opacity: 0.4;
    filter: alpha(opacity=40);
}

/*==================
 FRANCHISE SECTION
====================*/

.mu-title h1 {
    font-family: "Kaushan Script", cursive !important;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    color: #88381f;
}

.mu-title p {
    font-size: 26px;
    font-weight: 200;
    margin: 0px;
    font-family: "Viga", sans-serif !important;
    color: #666565;
    text-transform: uppercase;
}

#mu-contact {
    background-color: #f8f8f8;
    display: inline;
    float: left;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 20px;
    padding-bottom: 40px;
    width: 100%;
}

.mu-contact-area {
    display: inline;
    float: left;
    width: 100%;
}

.mu-contact-content {
    display: inline;
    float: left;
    margin-top: 30px;
    width: 100%;
}

.mu-contact-left {
    display: inline;
    float: left;
    font-family: "Raleway", sans-serif !important;
    width: 100%;
    font-weight: 800;
}

.mu-contact-left .form-group {
    margin-bottom: 25px !important;
}

.mu-contact-left .form-group label {
    font-size: 16px !important;
    margin-bottom: 8px !important;
    letter-spacing: 0.5px !important;
}

.mu-contact-form input[type="text"],
.mu-contact-form input[type="email"] {
    color: #000;
    border-radius: 0;
    height: 40px;
}

.mu-contact-form input[type="text"]:focus,
.mu-contact-form input[type="email"]:focus {
    box-shadow: none;
}

.mu-contact-form input[type="text"]:focus,
.mu-contact-form input[type="email"]:focus,
.mu-contact-form textarea:focus {
    border-color: #88381f;
}

.mu-contact-form textarea {
    color: #000;
    border-radius: 0;
    padding: 10px;
}

.mu-contact-form textarea:focus {
    box-shadow: none;
    outline-color: #88381f !important;
}

.success {
    padding: 1em;
    margin-bottom: 0.75rem;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    color: #468847;
    background-color: #dff0d8;
    border: 1px solid #d6e9c6;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.error {
    padding: 1em;
    margin-bottom: 0.75rem;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    color: #b94a48;
    background-color: #f2dede;
    border: 1px solid rgba(185, 74, 72, 0.3);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

@media (max-width: 767px) {
    .mu-contact-right {
        padding-top: 30px;
        padding-bottom: 0px;
    }

    #mu-contact {
        padding-bottom: 0px;
    }
}
</style>
