<template>
	<!-- Start slides -->
	<!-- <div id="slides" class="cover-slides tw-bg-transparent md:tw-hidden tw-h-full tw-w-full"> -->

		<div id="slides" class="carousel slide tw-bg-transparent md:tw-hidden" data-ride="carousel">

			<!-- Indicators -->
			<!-- <ul class="carousel-indicators">
				<li data-target="#demo" data-slide-to="0" class="active"></li>
				<li data-target="#demo" data-slide-to="1"></li>
				<li data-target="#demo" data-slide-to="2"></li>
			</ul> -->
			
			<!-- The slideshow -->
			<div class="carousel-inner">
				<div v-for="(img, index) in sliderImgs" :key="img">
					<transition name="slide">
					<div v-if="currentSlide === index" class="text-center">
						<div class="carousel-item" :class="{'active': currentSlide === index}"><img :src="'/storage/images/coco/'+img" alt="Slide Image" width="1920" height="1080"></div>
					</div>
				</transition>	
					
				</div>
				
			</div>
			
			<!-- Left and right controls -->
			<!-- <a class="carousel-control-prev" href="#slides" data-slide="prev">
				<span class="carousel-control-prev-icon"></span>
			</a>
			<a class="carousel-control-next" href="#slides" data-slide="next">
				<span class="carousel-control-next-icon"></span>
			</a> -->
		</div>




		<!-- <div class="tw-relative tw-h-full">
			<div v-for="(img, index) in sliderImgs" :key="img" class="tw-overflow-hidden tw-w-full tw-h-full tw-absolute">
				<transition name="slide">
					<div v-if="currentSlide === index" class="text-center">
						<img :src="'/storage/images/coco/'+img" class="tw-object-center md:tw-object-cover tw-w-full" alt="Slides">
					</div>
				</transition>						 
			</div>
		</div> -->
	<!-- </div> -->

	<div id="slides" class="cover-slides tw-bg-transparent md:tw-block tw-hidden">
		<div class="tw-relative tw-h-full">
			<div v-for="(img, index) in imgMobile" :key="index" class="tw-overflow-hidden tw-w-full tw-h-full tw-absolute">
				<transition name="slide">
					<div v-if="currentSlide === index" class="text-center">
						<img :src="'/storage/images/coco/'+img" class="tw-object-center md:tw-object-cover tw-h-screen tw-w-full" alt="Slides">
					</div>
				</transition>						
			</div>
		</div>
	</div>
	<!-- End slides -->
</template>

<script>
export default {
  name: "Slider",
  data() {
	  return {
		  currentSlide: 1,
		  interval: "",
		  sliderImgs: [
			  	"slider_image4.jpg",
				"slider_image0.jpg",
				"slider_image1.jpg",
				"slider_image2.jpg",
				"slider_image3.jpg",
				"slider_image4.jpg",
				// "slider__image5.jpg",
			],
			imgMobile: [
				"image__mobile0.jpeg",
				"image__mobile1.jpeg",
				"image__mobile2.jpeg",
				// "image__mobile3.jpg",
				"image__mobile4.jpeg",
				// "image__mobile5.jpg",
			],
	  }
  },
  methods: {
	makeActive(index) {
		this.currentSlide = index;
	}
  },
  created() {
	this.interval = setInterval(() => {
		if (this.currentSlide === 3) {
			this.currentSlide = 0;
		} else{
			this.currentSlide++;
		}		  
	}, 3000);
  },
  beforeUnmount() {
	  clearInterval(this.interval);
  },
};
</script>

<style scoped>

.carousel-inner img {
    width: 100%;
    height: 100%;
  }

/*------------------------------------------------------------------
    Slider
-------------------------------------------------------------------*/

.cover-slides{
	height: 100vh;
	margin-bottom: 60px;
}
.slides-navigation a {
	background: #cfa671;
    position: absolute;
    height: 70px;
    width: 70px;
    top: 50%;
    font-size: 20px;
    display: block;
    color: #fff;
	line-height: 80px;
	text-align: center;
    transition: all .3s ease-in-out;
}
.slides-navigation a i{
	font-size: 40px;
}
.slides-navigation a:hover {
	background: #010101;
}
.cover-slides .container{
	height: 100%;
	position: relative;
	z-index: 2;
}
.cover-slides .container > .row {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.cover-slides .container > .row {
    height: 100%;
}

.btn{
	text-transform: uppercase;
	padding: 19px 36px;
}
.btn{
	display: inline-block;
	font-weight: 600;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border: 2px solid transparent;
	padding: 12px 30px;
	font-size: 16px;
	line-height: 1.5;
	border-radius: .1875rem;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.btn-outline-new-white {
    color: #fff;
    background-color: transparent;
    background-image: none;
    border-color: #cfa671;
}
.btn-outline-new-white:hover {
    color: #ffffff;
    background-color: #cfa671;
    border-color: #cfa671;
}

.overlay-background {
    background: #333;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
	opacity: 0.5;
}

.cover-slides h1{
	font-family: 'Arbutus Slab', serif;
	font-weight: 500;
	font-size: 64px;
	color: #fff;
}
.cover-slides p{
	font-size: 18px;
	color: #fff;
}
.slides-pagination a{
	border: 2px solid #ffffff;
}
.slides-pagination a.current{
	background: #cfa671;
	border: 2px solid #cfa671;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-enter-active,
.slide-leave-active {
  transition: all 2s ease;
}

.slide-enter-from {
	/* opacity: 0; */
  	transform: translateX(100%);
}

.slide-leave-to {
  	/* opacity: 0; */
  	transform: translateX(-100%);
}

@media (max-width: 767px) {
	.cover-slides{
		/* height: 100vh; */
		margin-bottom: 0px;
	}
}

</style>