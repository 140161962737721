<template>
    <div class="fbnewicon">
        <a class="fbnew tw-flex tw-justify-center tw-items-center" href="https://www.facebook.com/cococurryindia/" target="_blank">
            <span class="tw-text-2xl tw-block tw-py-1 tw-px-3"><i class="fab fa-facebook-f"></i></span>
            <span class="followus tw-block tw-text-xs tw-pr-3">Follow us</span>
        </a>
        </div>

        <div class="instanewicon">
        <a class="instanew tw-flex tw-justify-center tw-items-center" href="https://www.instagram.com/cocconcurryindia" target="_blank">
            <span class="tw-text-2xl tw-block tw-py-1 tw-px-2.5"><i class="fab fa-instagram"></i></span>
            <span class="followus tw-block tw-text-xs tw-pr-3">Follow us</span>
        </a>
    </div>
</template>

<script>
export default {
    name: 'Social'
}
</script>

<style scoped>

.fbnewicon {
  border-radius: 4px;
  top: 385px;
  position: fixed;
  right: -64px;
  z-index: 150;
  transition: ease-in-out .6s;
  -webkit-transition: ease-in-out .6s;
  background-color: #166fe5;
  color: #ffffff;
  text-decoration: none;
}
.fbnew:hover {
  color: #ffffff;
  text-decoration: none;
}

.fbnewicon:hover {
  right: -5px;  
}

.instanewicon {
  border-radius: 4px;
  top: 430px;
  position: fixed;
  right: -65px;
  z-index: 150;
  transition: ease-in-out .6s;
  -webkit-transition: ease-in-out .6s;
  background-color: #C13584;
  color: #ffffff;
  text-decoration: none;
}

.instanew:hover {
  color: #ffffff;
  text-decoration: none;
}

.instanewicon:hover {
  right: -5px;  
}

</style>