<template>
    <div class="tw-flex tw-flex-col tw-overflow-y-hidden">
        <Navbar />

        <router-view></router-view>

    <Footer />

   </div>
</template>

<script>
import Navbar from "./Header/Navbar";
import Footer from "./Footer";
export default {
  name: "App",
  components: {
      Navbar,
      Footer,
   },

   methods: {

   },

   created() {

   }
};
</script>

<style scoped>
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-zoom.css');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* background: #e9ecef !important; */
  background: #f8f8f8 !important;
}


</style>
