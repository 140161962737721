<template>

	<!-- Start Footer -->
	<footer class="footer-area bg-f">
		<div class="container">
			<div class="tw-flex tw-flex-col tw-justify-center tw-items-center">
				<div>
					<h3 class="tw-text-center">CONTACT US</h3>
					<hr class=" tw-mb-6 md:tw-mb-5 tw-w-20 tw-bg-gray-300 tw-mx-auto">
					<div class="tw-flex tw-justify-center tw-items-center">
						<router-link tag="a" to="/about-us" class="tw-block tw-text-sm hover:tw-no-underline hover:tw-text-gray-200 tw-text-gray-300 tw-mb-5">About Us&nbsp;|&nbsp;</router-link>

						<router-link tag="a" to="/contact-us" class="tw-block tw-text-sm hover:tw-no-underline hover:tw-text-gray-200 tw-text-gray-300 tw-mb-5">Contact Us&nbsp;|&nbsp;</router-link>

						<router-link tag="a" to="/franchise" class="tw-block tw-text-sm hover:tw-no-underline hover:tw-text-gray-200 tw-text-gray-300 tw-mb-5">Franchise</router-link>
					</div>
				</div>

			</div>
		</div>

		<div class="copyright">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<p class="company-name">All Rights Reserved. &copy; 2021 <router-link to="/" tag="a" class=" tw-no-underline hover:tw-no-underline">Cocco n Curry </router-link>Restaurants</p>
					</div>
				</div>
			</div>
		</div>

	</footer>
	<!-- End Footer -->
</template>

<script>
export default {
    name: "Footer",
}
</script>

<style scoped>


/*------------------------------------------------------------------
    Footer
-------------------------------------------------------------------*/

.footer-area{
	padding-top: 40px;
	padding-bottom: 0px;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 220px;
}
.bg-f{
	/* background-image: url("../images/footer-bg.jpg"); */
	background-attachment: scroll;
	background-clip: initial;
	background-color: #fc3b0a !important;
	background-origin: initial;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
}
.bg-f::before {
    background: #010101;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    /* z-index: 1; */
	opacity: 0.8;
}

.footer-area .container{
	position: relative;
	/* z-index: 1; */
}
.footer-area h3{
	color: #ffffff;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 500;
	font-family: 'Roboto Condensed', sans-serif !important;
	align-items: center;
	/* margin-bottom: 5px; */
}
.footer-area p{
	font-size: 15px;
	color: rgba(255, 255, 255, 0.8);
	margin: 0;
	/* padding-bottom: 10px; */
}

.arrow-right{
	position: relative;
}
.arrow-right::before {
    content: "\f101";
	font-family: 'Font Awesome 5 Free';
	position: absolute;
	right: 0px;
	top: 0;
	color: #fff;
	font-size: 60px;
	line-height: 60px;
}


.footer-area p.lead{
	font-size: 19px;
	color: #ffffff;
	margin-bottom: 0px;
}
.footer-area p.lead a{
	color: #ffffff;
	margin-bottom: 15px;
}
.footer-area p.lead a:hover{
	color: #c4806c;
}
.footer-area p a{
	font-size: 18px;
	color: #ffffff;
}
.footer-area p a:hover{
	color: #c4806c;
}

.f-social {
    padding-bottom: 10px;
    margin: 0px;
    margin-top: 20px;
}
.f-social li a {
    font-size: 18px;
    color: rgba(214, 81, 6);
	width: 38px;
	height: 38px;
	display: block;
	text-align: center;
	line-height: 38px;
	background: #ffffff;
	border-radius: 4px;
}

.copyright{
	margin-top: 20px;
	position: relative;
	display: block;
	background-color: #88381f;
	border-top: 1px solid rgba(207, 166, 113);
	padding: 30px 0;
	/* z-index: 1; */
}

.copyright .company-name{
	font-size: 14px;
	text-align: center;
}
.copyright .company-name a{
	font-size: 14px;
	font-weight: 600;
}
</style>
